<template>
  <div class="login-page">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">Login</h1>
        </div>
      </div>
    </section>

    <!-- Login Form Section -->
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <form @submit.prevent="submit" class="box">
              <!-- Email Field -->
              <div class="field">
                <label for="email" class="label">Email</label>
                <div class="control has-icons-left">
                  <input
                    type="email"
                    placeholder="e.g. polish@folk.com"
                    class="input"
                    v-model="email"
                    autofocus
                    required
                    id="email"
                    name="email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <!-- Password Field -->
              <div class="field">
                <label for="password" class="label">Password</label>
                <div class="control has-icons-left">
                  <input
                    type="password"
                    placeholder="*******"
                    class="input"
                    v-model="password"
                    required
                    id="password"
                    name="password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <!-- Sign-Up Redirect -->
              <div class="has-text-centered">
                <p class="is-size-7">
                  Don't have an account?
                  <router-link to="/register" class="has-text-primary">Sign Up</router-link>
                </p>
              </div>

              <!-- Submit Button -->
              <div class="field">
                <button type="submit" class="button is-success is-fullwidth">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import { useUserStore } from "../store";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const store = useUserStore();
    store.hydrateFromLocalStorage();
    const email = ref("");
    const password = ref("");
    const router = useRouter();
    const route = useRoute();

    const submit = async () => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email.value, password.value);
        store.login(email.value);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userEmail", email.value);

        const redirectPath = route.query.redirect || "/";
        router.push(redirectPath);
      } catch (err) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: err.message,
        });
      }
    };

    return {
      email,
      password,
      submit,
    };
  },
};
</script>

<style scoped lang="scss">
/* Hero Section */
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
  margin-bottom: 0;
}

.hero.is-primary {
  background-color: transparent;
}

/* Form Styling */
.box {
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.field {
  margin-bottom: 1.5rem;
}

.input {
  padding: 0.75rem;
  border-radius: 5px;
}

.button.is-success {
  font-weight: bold;
  margin-top: 1rem;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  .box {
    padding: 1.5rem;
  }

  .input {
    font-size: 0.9rem;
  }
}
</style>
